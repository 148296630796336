exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ar-classes-js": () => import("./../../../src/pages/ar-classes.js" /* webpackChunkName: "component---src-pages-ar-classes-js" */),
  "component---src-pages-eduaccess-js": () => import("./../../../src/pages/eduaccess.js" /* webpackChunkName: "component---src-pages-eduaccess-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-psychometric-tests-js": () => import("./../../../src/pages/psychometric-tests.js" /* webpackChunkName: "component---src-pages-psychometric-tests-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-virtual-labs-js": () => import("./../../../src/pages/virtual-labs.js" /* webpackChunkName: "component---src-pages-virtual-labs-js" */)
}

